<template>
    <span class="word">
        <span class="word__inner" ref="word" v-html="word"> </span>
    </span>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default {
    name: 'WordAnimation',
    props: {
        word: {
            type: String,
            required: true,
        },
        duration: {
            type: [String, Number],
            default: '1',
        },
        delay: {
            type: [String, Number],
            default: 0,
        },
        markers: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    watch: {
        '$route.path': {
            handler: function() {
                ScrollTrigger.refresh()
            },
        },
    },
    mounted() {
        setTimeout(() => {
            this.setTrigger(this.duration, this.delay, this.markers)
        }, 1000)
    },
    methods: {
        setTrigger(duration, delay, markers) {
            const word = this.$refs.word

            gsap.registerPlugin(ScrollTrigger)

            const animation = gsap.to(word, {
                onStart: () => {
                    animation.pause()
                    setTimeout(() => {
                        animation.play()
                    }, +delay * 1000)
                },
                y: '0%',
                duration: +duration,
                scrollTrigger: {
                    start: '-50% bottom',
                    end: 'bottom top',
                    trigger: word,
                    markers: markers,
                    once: true,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.word {
    overflow: hidden;
    vertical-align: middle;

    &,
    &__inner {
        display: inline-block;
    }
    &__inner {
        @media (max-width: 767px) {
            transform: none;
        }
        transform: translateY(100%);
    }
}
</style>
